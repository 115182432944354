<!-- eslint-disable -->
<template>
<v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <img id="bg" :src="bgUrl" class="center-cropped">
    <v-row
        align="center"
        justify="center"
    >

        <v-col
            cols="12"
            sm="6"
            lg="3"
            class="text-center header"
        >
            Logout process..

        </v-col>
    </v-row>

  </v-container>
</template>
<script>
import secureStorage from '@/utils/secureStorage'

export default {
  name: 'logout',
  computed: {
    bgUrl() {
      return require('@/assets/KSK-PKS-001.jpg')
    }
  },
  mounted () {
    this.logOut()
  },
  methods:{
    logOut () {
      console.log('logout')
      this.$store.state.user = null
      secureStorage.removeItem('user')
      secureStorage.removeItem('jwt')
      secureStorage.removeItem('userpos')
      secureStorage.removeItem('orgall')
      secureStorage.removeItem('userall')
      secureStorage.removeItem('userposall')
      secureStorage.removeItem('currRole')
      secureStorage.removeItem('posall')

      this.$router.push('/login')
      // window.location.href = "/#/login"
    },
    
  }
}
</script>
<style>
  #bg{
    position:fixed;
    top:0;
    left:0;
    z-index:0;
    min-width:100%;
    min-height:100%
  }
  .center-cropped{
    object-fit:none;
    object-position:center;
    height:100px;
    width:100px
  }

  #cardlogin{
    background-color: rgba(0, 0, 0, 0.7);
  }

  #whitetxt{
    color: white !important;
  }
</style>
<!-- eslint-enable -->